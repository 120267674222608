import { NETWORK_ID, WALLET_NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import { walletNetworkById, walletNetworkByVault, walletNetworkByWalletId, } from '@/web3-server/wallet/data/network';
import { DECIMAL_TYPE } from '@/web3-server/types/decimal/constants';
import Decimal from 'decimal.js';
import checker from '@/web3-server/utils/checker';
import { devError } from '@/utils/devError';
import clearNumLine from '@/web3-server/utils/composables/clear-num-line';
import { STRATEGY_ID, STRATEGY_VAULT } from '@/web3-server/web3-strategies/types/config/constants';
const linker = {
    bridgeNetwork: getNetworkIdAndBridge,
    getNetworkId: getNetworkId,
    getWalletNetwork: getWalletNetwork,
    getWalletNetworkId: getWalletNetworkId,
    getChainId: getChainId,
    getStrategyVault: getStrategyVault,
    decimal: {
        getWei: getDecimalWei,
        getSimple: getDecimalSimple,
    },
};
function getNetworkIdAndBridge(selectedNetwork) {
    let result = undefined;
    if (selectedNetwork) {
        if (typeof selectedNetwork === 'string') {
            if (Object.values(STRATEGY_VAULT).includes(selectedNetwork)) {
                const network = walletNetworkByVault[selectedNetwork];
                if (network && network.crossChain) {
                    result = {
                        id: network.crossChain.id,
                        bridgeType: network.crossChain.bridgeType,
                        networkId: network.id,
                    };
                }
            }
            else if (Object.values(NETWORK_ID).includes(selectedNetwork)) {
                const network = walletNetworkById[selectedNetwork];
                if (network && network.crossChain) {
                    result = {
                        id: network.crossChain.id,
                        bridgeType: network.crossChain.bridgeType,
                        networkId: network.id,
                    };
                }
            }
        }
        else {
            if (selectedNetwork && 'feeLibrary' in selectedNetwork) {
                result = {
                    id: selectedNetwork.id,
                    bridgeType: selectedNetwork.bridgeType,
                    networkId: selectedNetwork.networkId,
                };
            }
            else if (selectedNetwork && 'crossChain' in selectedNetwork && selectedNetwork.crossChain) {
                result = {
                    id: selectedNetwork.crossChain.id,
                    bridgeType: selectedNetwork.crossChain.bridgeType,
                    networkId: selectedNetwork.id,
                };
            }
        }
    }
    return result;
}
function getNetworkId(network) {
    let networkId = NETWORK_ID.BNB;
    if (typeof network === 'string') {
        if (Object.values(WALLET_NETWORK_ID).includes(network)) {
            networkId = walletNetworkByWalletId[network].id;
        }
        else if (Object.values(NETWORK_ID).includes(network)) {
            networkId = network;
        }
        else if (Object.values(STRATEGY_VAULT).includes(network)) {
            switch (network) {
                case STRATEGY_VAULT.BNB:
                    networkId = NETWORK_ID.BNB;
                    break;
                case STRATEGY_VAULT.BNB_V2:
                    networkId = NETWORK_ID.BNB;
                    break;
                case STRATEGY_VAULT.POLYGON:
                    networkId = NETWORK_ID.POLYGON;
                    break;
                case STRATEGY_VAULT.ETH:
                    networkId = NETWORK_ID.ETH;
                    break;
            }
        }
    }
    else if (typeof network === 'number') {
        switch (network) {
            case 56:
                networkId = NETWORK_ID.BNB;
                break;
            case 137:
                networkId = NETWORK_ID.POLYGON;
                break;
            case 1:
                networkId = NETWORK_ID.ETH;
                break;
        }
    }
    else {
        if ('crossChain' in network && network.crossChain) {
            networkId = network.id;
        }
        else if ('contractAddress' in network && network.feeLibrary) {
            networkId = network.networkId;
        }
        else if ('hex' in network && network.hex && network.int) {
            switch (network.int) {
                case 56:
                    networkId = NETWORK_ID.BNB;
                    break;
                case 137:
                    networkId = NETWORK_ID.POLYGON;
                    break;
                case 1:
                    networkId = NETWORK_ID.ETH;
                    break;
            }
        }
    }
    return networkId;
}
function getWalletNetworkId(network) {
    let walletNetworkId = WALLET_NETWORK_ID.BNB_STARGATE;
    if (typeof network === 'string') {
        if (Object.values(WALLET_NETWORK_ID).includes(network)) {
            walletNetworkId = network;
        }
        else if (Object.values(NETWORK_ID).includes(network)) {
            walletNetworkId = walletNetworkById[network].walletId;
        }
        else if (Object.values(STRATEGY_VAULT).includes(network)) {
            walletNetworkId = walletNetworkByVault[network].walletId;
        }
    }
    else if (typeof network !== 'string') {
        if ('crossChain' in network && network.crossChain) {
            walletNetworkId = network.walletId;
        }
        else if ('contractAddress' in network && network.feeLibrary) {
            walletNetworkId = network.walletId;
        }
    }
    return walletNetworkId;
}
function getWalletNetwork(network) {
    let walletNetwork = walletNetworkByWalletId.STARGATE_BNB;
    if (Object.values(WALLET_NETWORK_ID).includes(network)) {
        walletNetwork = walletNetworkByWalletId[network];
    }
    else if (Object.values(NETWORK_ID).includes(network)) {
        walletNetwork = walletNetworkById[network];
    }
    else if (Object.values(STRATEGY_VAULT).includes(network)) {
        walletNetwork = walletNetworkByVault[network];
    }
    return walletNetwork;
}
function getStrategyVault(network) {
    let vault = STRATEGY_VAULT.BNB;
    if (typeof network === 'string') {
        if (Object.values(WALLET_NETWORK_ID).includes(network)) {
            switch (network) {
                case WALLET_NETWORK_ID.BNB_STARGATE:
                    vault = STRATEGY_VAULT.BNB;
                    break;
                case WALLET_NETWORK_ID.POLYGON_STARGATE:
                    vault = STRATEGY_VAULT.POLYGON;
                    break;
                case WALLET_NETWORK_ID.ETH_STARGATE:
                    vault = STRATEGY_VAULT.ETH;
                    break;
                case WALLET_NETWORK_ID.ARBITRUM:
                    vault = STRATEGY_VAULT.ARBITRUM;
                    break;
            }
        }
        else if (Object.values(NETWORK_ID).includes(network)) {
            switch (network) {
                case NETWORK_ID.BNB:
                    vault = STRATEGY_VAULT.BNB;
                    break;
                case NETWORK_ID.POLYGON:
                    vault = STRATEGY_VAULT.POLYGON;
                    break;
                case NETWORK_ID.ETH:
                    vault = STRATEGY_VAULT.ETH;
                    break;
                case NETWORK_ID.ARBITRUM:
                    vault = STRATEGY_VAULT.ARBITRUM;
                    break;
            }
        }
        else if (Object.values(STRATEGY_ID).includes(network)) {
            const availableStrategyIds = {
                [STRATEGY_ID.BNB_STABLECOINS]: STRATEGY_VAULT.BNB,
                [STRATEGY_ID.BNB_ETH]: STRATEGY_VAULT.BNB,
                [STRATEGY_ID.BNB_BTC]: STRATEGY_VAULT.BNB,
                [STRATEGY_ID.BNB_BLID]: STRATEGY_VAULT.BNB,
                [STRATEGY_ID.BNB_BLID_USDT]: STRATEGY_VAULT.BNB,
                [STRATEGY_ID.BNB_ALTCOINS]: STRATEGY_VAULT.BNB,
                [STRATEGY_ID.POLYGON_USDT]: STRATEGY_VAULT.POLYGON,
                [STRATEGY_ID.POLYGON_USDC]: STRATEGY_VAULT.POLYGON,
                [STRATEGY_ID.POLYGON_MATIC]: STRATEGY_VAULT.POLYGON,
                [STRATEGY_ID.POLYGON_DAI]: STRATEGY_VAULT.POLYGON,
                [STRATEGY_ID.POLYGON_ETH]: STRATEGY_VAULT.POLYGON,
                [STRATEGY_ID.BNB_BNB]: STRATEGY_VAULT.BNB_V2,
                [STRATEGY_ID.BNB_USDT_V2]: STRATEGY_VAULT.BNB_V2,
                [STRATEGY_ID.ARBITRUM_USDC_E]: STRATEGY_VAULT.ARBITRUM,
                [STRATEGY_ID.DEV_BNB_USDT_DFORCE]: STRATEGY_VAULT.BNB_V2,
                [STRATEGY_ID.DEV_POLYGON_STABLECOINS]: STRATEGY_VAULT.POLYGON,
            };
            const getList = (vault) => {
                return Object.keys(availableStrategyIds).reduce((prev, key) => {
                    const strategyId = key;
                    const item = availableStrategyIds[strategyId];
                    if (item === vault) {
                        prev.push(strategyId);
                    }
                    return prev;
                }, []);
            };
            if (getList(STRATEGY_VAULT.BNB).includes(network)) {
                vault = STRATEGY_VAULT.BNB;
            }
            else if (getList(STRATEGY_VAULT.BNB_V2).includes(network)) {
                vault = STRATEGY_VAULT.BNB_V2;
            }
            else if (getList(STRATEGY_VAULT.POLYGON).includes(network)) {
                vault = STRATEGY_VAULT.POLYGON;
            }
            else if (getList(STRATEGY_VAULT.ETH).includes(network)) {
                vault = STRATEGY_VAULT.ETH;
            }
            else if (getList(STRATEGY_VAULT.ARBITRUM).includes(network)) {
                vault = STRATEGY_VAULT.ARBITRUM;
            }
        }
    }
    return vault;
}
function getChainId(network) {
    let chainId = walletNetworkByWalletId.STARGATE_BNB.chainId;
    if (typeof network === 'string') {
        if (Object.values(WALLET_NETWORK_ID).includes(network)) {
            chainId = walletNetworkByWalletId[network].chainId;
        }
        else if (Object.values(NETWORK_ID).includes(network)) {
            chainId = walletNetworkById[network].chainId;
        }
    }
    else if (typeof network !== 'string') {
        if ('crossChain' in network && network.crossChain) {
            chainId = network.chainId;
        }
        else if ('contractAddress' in network && network.feeLibrary) {
            chainId = walletNetworkByWalletId[network.walletId].chainId;
        }
    }
    return chainId;
}
function getFormattedDecimal(value, type) {
    return {
        type,
        value,
    };
}
function getDecimalWei(value) {
    const decimal = Decimal.isDecimal(value) ? value : new Decimal(clearNumLine(value));
    if (checker.decimal.isWei(decimal)) {
        return getFormattedDecimal(decimal, DECIMAL_TYPE.WEI);
    }
    else {
        devError('Number is not integer');
        return getFormattedDecimal(new Decimal(0), DECIMAL_TYPE.WEI);
    }
}
function getDecimalSimple(value) {
    const decimal = Decimal.isDecimal(value) ? value : new Decimal(clearNumLine(value));
    if (checker.decimal.isPositive(decimal)) {
        return getFormattedDecimal(decimal, DECIMAL_TYPE.SIMPLE);
    }
    else {
        devError('Number is not positive');
        return getFormattedDecimal(new Decimal(0), DECIMAL_TYPE.SIMPLE);
    }
}
export default linker;
