export var CURRENCY_ID;
(function (CURRENCY_ID) {
    CURRENCY_ID["BNB_BNB"] = "BNB_BNB";
    CURRENCY_ID["BNB_BUSD"] = "BNB_BUSD";
    CURRENCY_ID["BNB_USDT"] = "BNB_USDT";
    CURRENCY_ID["BNB_USDC"] = "BNB_USDC";
    CURRENCY_ID["BNB_DAI"] = "BNB_DAI";
    CURRENCY_ID["BNB_BLID"] = "BNB_BLID";
    CURRENCY_ID["BNB_BLID_USDT"] = "BNB_BLID_USDT";
    CURRENCY_ID["BNB_ETH"] = "BNB_ETH";
    CURRENCY_ID["BNB_BTC"] = "BNB_BTC";
    CURRENCY_ID["BNB_XRP"] = "BNB_XRP";
    CURRENCY_ID["BNB_XVS"] = "BNB_XVS";
    CURRENCY_ID["BNB_LTC"] = "BNB_LTC";
    CURRENCY_ID["BNB_ADA"] = "BNB_ADA";
    CURRENCY_ID["BNB_LINK"] = "BNB_LINK";
    CURRENCY_ID["BNB_DOT"] = "BNB_DOT";
    CURRENCY_ID["BNB_MATIC"] = "BNB_MATIC";
    CURRENCY_ID["POLYGON_USDT"] = "POLYGON_USDT";
    CURRENCY_ID["POLYGON_USDC"] = "POLYGON_USDC";
    CURRENCY_ID["POLYGON_DAI"] = "POLYGON_DAI";
    CURRENCY_ID["POLYGON_BLID"] = "POLYGON_BLID";
    CURRENCY_ID["POLYGON_ETH"] = "POLYGON_ETH";
    CURRENCY_ID["POLYGON_MATIC"] = "POLYGON_MATIC";
    CURRENCY_ID["ETH_BUSD"] = "ETH_BUSD";
    CURRENCY_ID["ETH_USDT"] = "ETH_USDT";
    CURRENCY_ID["ETH_USDC"] = "ETH_USDC";
    CURRENCY_ID["ETH_DAI"] = "ETH_DAI";
    CURRENCY_ID["ETH_BLID"] = "ETH_BLID";
    CURRENCY_ID["ETH_BTC"] = "BNB_BTC";
    CURRENCY_ID["ARB_USDC_E"] = "ARB_USDC.E";
    CURRENCY_ID["ARB_BLID"] = "ARB_BLID";
    CURRENCY_ID["GOERLI_USDT"] = "GOERLI_USDT";
})(CURRENCY_ID || (CURRENCY_ID = {}));
export var CURRENCY_TYPE;
(function (CURRENCY_TYPE) {
    CURRENCY_TYPE["BNB"] = "BNB";
    CURRENCY_TYPE["BUSD"] = "BUSD";
    CURRENCY_TYPE["USDT"] = "USDT";
    CURRENCY_TYPE["USDC"] = "USDC";
    CURRENCY_TYPE["USDC_E"] = "USDC.E";
    CURRENCY_TYPE["DAI"] = "DAI";
    CURRENCY_TYPE["BLID"] = "BLID";
    CURRENCY_TYPE["BLID_USDT"] = "BLID-USDT";
    CURRENCY_TYPE["ETH"] = "ETH";
    CURRENCY_TYPE["BTC"] = "BTC";
    CURRENCY_TYPE["XRP"] = "XRP";
    CURRENCY_TYPE["XVS"] = "XVS";
    CURRENCY_TYPE["LTC"] = "LTC";
    CURRENCY_TYPE["ADA"] = "ADA";
    CURRENCY_TYPE["LINK"] = "LINK";
    CURRENCY_TYPE["DOT"] = "DOT";
    CURRENCY_TYPE["MATIC"] = "MATIC";
})(CURRENCY_TYPE || (CURRENCY_TYPE = {}));
