import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import getAPYAndAPRForAllStrategies from '@/web3-server/web3/actions/strategies/multi/multi-storage-contract/get-apy-and-apr';
import { numWei } from '@/composables/useFormatCurrency';
import getTVLForAllStrategies from '@/web3-server/web3/actions/strategies/multi/multi-storage-contract/get-tvl';
import { getBoostingMaxAPYForAllStrategies } from '@/web3-server/web3/actions/strategies/multi/multi-boost/get-config-for-boosting';
import multiCallManager from '@/web3-server/web3/handler/multicall-manager';
import { useWeb3StrategiesStore } from '@/web3-server/web3-strategies';
import { getAddressesOfLogicContracts } from '@/web3-server/web3/actions/strategies/multi/multi-storage-contract/get-addresses-of-logic-contracts';
export default function getMultiGeneralInfoFromStorageContract(requestWithBLIDPriceByNetworks, listOfMinStrategyConfigById) {
    try {
        const web3Strategies = useWeb3StrategiesStore();
        const BLIDPriceWei = requestWithBLIDPriceByNetworks[0].value.BLIDPriceWei;
        const BLIDPriceSimple = numWei(BLIDPriceWei.toFixed());
        const listOfStrategyId = listOfMinStrategyConfigById.map((item) => item.strategyId);
        const requestByNetworks = requestWithBLIDPriceByNetworks.map((item) => {
            return {
                networkId: item.networkId,
                value: item.value.web3,
            };
        });
        const listOfStrategyConfigForMultiCall = listOfMinStrategyConfigById.reduce((prev, item) => {
            const strategy = web3Strategies.getById(item.strategyId);
            if (strategy && strategy.contract && strategy.contract.address && strategy.contract.abi) {
                prev.push({
                    id: item.strategyId,
                    networkId: item.networkId,
                    contractAddress: strategy.contract.address,
                    abi: strategy.contract.abi,
                });
            }
            return prev;
        }, []);
        const multiCall = multiCallManager(requestByNetworks, listOfStrategyConfigForMultiCall);
        const logicContractAddressesPromise = getAddressesOfLogicContracts(requestByNetworks, multiCall, listOfMinStrategyConfigById
            .filter((item) => !item.value.logicAddress)
            .map((item) => item.strategyId));
        const tvlPromise = getTVLForAllStrategies(requestByNetworks, multiCall, BLIDPriceSimple, listOfMinStrategyConfigById);
        const apyAndDateLastDistributionPromise = getAPYAndAPRForAllStrategies(BLIDPriceSimple, multiCall, listOfMinStrategyConfigById);
        const apyPromise = apyAndDateLastDistributionPromise.then((response) => response.listOfAPYAndAPRByStrategyId);
        const dateOfLastDistributionPromise = apyAndDateLastDistributionPromise.then((response) => response.dateLastDistributionInMS);
        const boostingPromise = Promise.all([apyPromise]).then(async (values) => {
            const apyData = values[0];
            const isBoostingV2 = true;
            return getBoostingMaxAPYForAllStrategies(multiCall, listOfMinStrategyConfigById, BLIDPriceWei, apyData).then((boost) => {
                return {
                    response: boost,
                    isBoostingV2,
                };
            });
        });
        const res = listOfStrategyId.map((strategyId) => {
            return {
                tvlPromise: tvlPromise.then((tvl) => tvl.find((item) => item.strategyId === strategyId)?.value),
                apyPromise: apyPromise.then((apy) => apy.find((item) => item.strategyId === strategyId)?.value),
                boostingPromise: boostingPromise.then((boosting) => boosting.response.find((item) => item.strategyId === strategyId)?.value),
                dateOfLastDistributionPromise: dateOfLastDistributionPromise.then((date) => date.find((item) => item.strategyId === strategyId)?.value),
            };
        });
        return {
            tvlPromise,
            apyPromise,
            boostingPromise,
            dateOfLastDistributionPromise,
            logicContractAddressesPromise,
        };
    }
    catch (e) {
        devError(DEV_ERROR_CODE.GET_GENERAL_INFO_CONTRACT);
        return undefined;
    }
}
