import { getGeneralInfoFromStorageContract } from '@/web3-server/web3/actions/strategies/storage-contract/storage-contract';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import { getGeneralInfoFromFarming, getGeneralInfoFromStaking, } from '@/web3-server/web3/actions/strategies/master-contract/master-contract';
import { checkVars, getServerAddressByVault, requestAPI, } from '@/web3-server/web3/handler/inspectors';
import { CONTRACT_TYPE } from '@/web3-server/web3/types/strategies/constants';
import getPortfolio from '@/web3-server/web3/actions/strategies/portfolio/get-portfolio';
import { devError } from '@/utils/devError';
import getGraphDataByTVL from '@/web3-server/web3/actions/strategies/graph/get-tvl';
import getGraphDataByAPY from '@/web3-server/web3/actions/strategies/graph/get-apy';
import boostingActions from '@/web3-server/web3/actions/strategies/boost';
import getGeneralInfoFromStorageContractForAllStrategies from '@/web3-server/web3/actions/strategies/multi/multi-storage-contract/multi-storage-contract';
import { useWeb3StrategiesStore } from '@/web3-server/web3-strategies';
const strategiesActions = {
    ...boostingActions,
    async getGeneralDataForAllStrategies(strategyContract, BLIDPrice, BLIDTokenAddress, forFarming, currentNetwork, listOfNetworks) {
        if (strategyContract &&
            strategyContract.abi &&
            strategyContract.type &&
            checkVars([strategyContract.address, BLIDPrice])) {
            if (strategyContract.type === CONTRACT_TYPE.STORAGE) {
                return this.getGeneralDataFromStorageContractForAllStrategies(listOfNetworks);
            }
            else if (strategyContract.type === CONTRACT_TYPE.STAKING && BLIDTokenAddress) {
                return this.getGeneralDataForStaking(BLIDPrice, BLIDTokenAddress, strategyContract, currentNetwork);
            }
            else if (strategyContract.type === CONTRACT_TYPE.FARMING &&
                BLIDTokenAddress &&
                forFarming &&
                forFarming.lpTokenAddress) {
                return this.getGeneralInfoFromFarming(BLIDPrice, BLIDTokenAddress, forFarming.lpTokenAddress, strategyContract, currentNetwork);
            }
            else {
                throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
            }
        }
        else {
            throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
        }
    },
    async getGeneralData(strategyContract, BLIDPrice, BLIDTokenAddress, forFarming, networkId) {
        if (strategyContract &&
            strategyContract.abi &&
            strategyContract.type &&
            checkVars([strategyContract.address, BLIDPrice])) {
            if (strategyContract.type === CONTRACT_TYPE.STORAGE) {
                return this.getGeneralDataForStorageContract(strategyContract, networkId);
            }
            else if (strategyContract.type === CONTRACT_TYPE.STAKING && BLIDTokenAddress) {
                return this.getGeneralDataForStaking(BLIDPrice, BLIDTokenAddress, strategyContract, networkId);
            }
            else if (strategyContract.type === CONTRACT_TYPE.FARMING &&
                BLIDTokenAddress &&
                forFarming &&
                forFarming.lpTokenAddress) {
                return this.getGeneralInfoFromFarming(BLIDPrice, BLIDTokenAddress, forFarming.lpTokenAddress, strategyContract, networkId);
            }
            else {
                throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
            }
        }
        else {
            throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
        }
    },
    async getTVLForChart(contractType, contractAddress) {
        if (contractType &&
            (contractType !== CONTRACT_TYPE.STORAGE ||
                (contractType === CONTRACT_TYPE.STORAGE && contractAddress))) {
            return getGraphDataByTVL(contractType, contractAddress, getServerAddressByVault());
        }
        else {
            devError(DEV_ERROR_CODE.WEB_STORAGE_NO_VAR);
            return [];
        }
    },
    async getAPYForChart(contractType, contractAddress) {
        if (contractType &&
            (contractType !== CONTRACT_TYPE.STORAGE ||
                (contractType === CONTRACT_TYPE.STORAGE && contractAddress))) {
            return getGraphDataByAPY(contractType, contractAddress, getServerAddressByVault());
        }
        else {
            devError(DEV_ERROR_CODE.WEB_STORAGE_NO_VAR);
            return [];
        }
    },
    async getPortfolio(storageAddress) {
        if (storageAddress) {
            return getPortfolio(storageAddress, getServerAddressByVault());
        }
        else {
            devError(DEV_ERROR_CODE.WEB_STORAGE_NO_VAR);
            return null;
        }
    },
    getGeneralDataFromStorageContractForAllStrategies(listOfNetworks) {
        const web3Strategies = useWeb3StrategiesStore();
        const requestByNetworks = listOfNetworks.map((networkId) => {
            return {
                networkId,
                value: requestAPI('defaultWithPrice', { web3: { networkId } }),
            };
        });
        if (requestByNetworks?.length &&
            requestByNetworks.filter((request) => request.value.isWithPrice).length ===
                requestByNetworks.length) {
            const listOfMultiStrategyConfig = web3Strategies.list
                .filter((item) => item.contract &&
                item.contract.type === CONTRACT_TYPE.STORAGE &&
                listOfNetworks.includes(item.network))
                .map((item) => {
                return {
                    strategyId: item.id,
                    networkId: item.network,
                    value: {
                        networkId: item.network,
                        logicAddress: 'logicAddress' in item.contract && item.contract.logicAddress
                            ? item.contract.logicAddress
                            : undefined,
                        disableAPY: item.disableAPY,
                    },
                };
            });
            return getGeneralInfoFromStorageContractForAllStrategies(requestByNetworks.map((request) => {
                return {
                    networkId: request.networkId,
                    value: request.value.getWithPrice,
                };
            }), listOfMultiStrategyConfig);
        }
        else {
            throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
        }
    },
    getGeneralDataForStorageContract(strategyContract, networkId) {
        const request = requestAPI('defaultWithPrice', { web3: { networkId } });
        if (request.isWithPrice &&
            strategyContract &&
            strategyContract.abi &&
            strategyContract.address) {
            return getGeneralInfoFromStorageContract(request.getWithPrice, strategyContract, networkId);
        }
        else {
            throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
        }
    },
    getGeneralDataForStaking(BLIDPrice, BLIDTokenAddress, strategyContract, networkId) {
        const request = requestAPI('default', { web3: { networkId } });
        if (request.is &&
            strategyContract &&
            strategyContract.abi &&
            checkVars([BLIDPrice, BLIDTokenAddress, strategyContract.address])) {
            return getGeneralInfoFromStaking(request.get, BLIDPrice, BLIDTokenAddress, strategyContract);
        }
        else {
            throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
        }
    },
    getGeneralInfoFromFarming(BLIDPrice, BLIDTokenAddress, lpTokenAddress, strategyContract, networkId) {
        const request = requestAPI('default', { web3: { networkId } });
        if (request.is &&
            strategyContract &&
            strategyContract.abi &&
            checkVars([BLIDPrice, BLIDTokenAddress, lpTokenAddress, strategyContract.address])) {
            return getGeneralInfoFromFarming(request.get, BLIDPrice, BLIDTokenAddress, lpTokenAddress, strategyContract);
        }
        else {
            throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
        }
    },
};
export default strategiesActions;
