import { readActiveWallets, supportedWallets } from '@lifi/wallet-management';
import { useWalletStore } from '@/web3-server/wallet';
import { markRaw } from 'vue';
import { LI_FI_WALLET_NAMES } from '@/web3-server/wallet/types/li-fi/constants';
import linker from '@/web3-server/utils/linker';
import { NETWORK_SWITCH_STATUSED } from '@/web3-server/wallet/types/provider/constants';
import infra from '@/infra';
import { devError } from '@/utils/devError';
const liFiWidgetActions = {
    async autoConnectToLiFi(bloctoSDK) {
        try {
            const persistedActiveWallets = readActiveWallets();
            let activeWallets = supportedWallets.filter((wallet) => persistedActiveWallets.some((perstistedWallet) => perstistedWallet.name === wallet.name) && wallet.installed());
            const whichWalletToChoose = this.whichWalletToChoose();
            if (whichWalletToChoose.isBlocto) {
                const currentWallet = supportedWallets.find((wallet) => wallet.name === LI_FI_WALLET_NAMES.DEFAULT);
                if (currentWallet && 'windowProvider' in currentWallet) {
                    currentWallet.windowProvider = bloctoSDK?.ethereum;
                    activeWallets = [currentWallet];
                }
            }
            else if (activeWallets.length) {
                let name = null;
                if (whichWalletToChoose.isWalletConnect) {
                    name = LI_FI_WALLET_NAMES.WALLET_CONNECT;
                }
                else if (whichWalletToChoose.isExodus) {
                    name = LI_FI_WALLET_NAMES.EXODUS;
                }
                if (name) {
                    activeWallets = activeWallets.filter((wallet) => wallet.name === name);
                }
                else if (whichWalletToChoose.isDefaultExtension) {
                    activeWallets = activeWallets.filter((wallet) => wallet.name !== LI_FI_WALLET_NAMES.WALLET_CONNECT);
                }
            }
            if (!activeWallets.length) {
                return undefined;
            }
            await this.liFiWidget.walletManagement.autoConnect(activeWallets);
            activeWallets[0].on('walletAccountChanged', handleWalletUpdate);
            await handleWalletUpdate(activeWallets[0]);
            return activeWallets[0];
        }
        catch (e) {
            devError(e);
            return undefined;
        }
    },
    async connectToLiFi(bloctoSDK) {
        const installedWallets = supportedWallets.filter((wallet) => wallet.installed());
        let currentWallet = installedWallets.find((wallet) => wallet.name !== LI_FI_WALLET_NAMES.WALLET_CONNECT);
        const whichWalletToChoose = this.whichWalletToChoose();
        if (whichWalletToChoose.isBlocto) {
            currentWallet = supportedWallets.find((wallet) => wallet.name === LI_FI_WALLET_NAMES.DEFAULT);
            if (currentWallet && 'windowProvider' in currentWallet) {
                const sdk = bloctoSDK || this.blocto.sdk;
                currentWallet.windowProvider = sdk?.ethereum;
            }
        }
        else {
            let name = null;
            if (whichWalletToChoose.isWalletConnect) {
                name = LI_FI_WALLET_NAMES.WALLET_CONNECT;
            }
            else if (whichWalletToChoose.isExodus) {
                name = LI_FI_WALLET_NAMES.EXODUS;
            }
            if (name) {
                currentWallet = installedWallets.find((wallet) => wallet.name === name);
            }
        }
        if (currentWallet) {
            try {
                await this.liFiWidget.walletManagement.connect(currentWallet);
                currentWallet.on('walletAccountChanged', handleWalletUpdate);
                await handleWalletUpdate(currentWallet);
            }
            catch (e) {
                devError(e);
            }
        }
    },
    async disconnectToLiFi() {
        if (this.liFiWidget.wallet.fully) {
            try {
                await this.liFiWidget.walletManagement.disconnect(this.liFiWidget.wallet.fully);
                this.liFiWidget.wallet.fully.removeAllListeners();
                handleWalletUpdate(undefined);
            }
            catch (e) {
                devError(e);
            }
        }
    },
    async switchChainForLiFi(chainId) {
        try {
            const whichWalletToChoose = this.whichWalletToChoose();
            if (whichWalletToChoose.isBlocto || whichWalletToChoose.isWalletConnect) {
                const status = await this.switchNetwork(linker.getNetworkId(chainId));
                if (status && status === NETWORK_SWITCH_STATUSED.SUCCESS) {
                    await infra.wallet.connect();
                }
                else {
                    return false;
                }
            }
            else {
                await this.liFiWidget.wallet.fully?.switchChain(chainId);
            }
            await handleWalletUpdate(this.liFiWidget.wallet.fully);
            return true;
        }
        catch (e) {
            return false;
        }
    },
    async addChainToLiFi(chainId) {
        try {
            await this.liFiWidget.wallet.fully?.addChain(chainId);
            handleWalletUpdate(this.liFiWidget.wallet.fully);
            return true;
        }
        catch {
            return false;
        }
    },
    async addTokenToLiFi(chainId, token) {
        try {
            await this.liFiWidget.wallet.fully?.addToken(chainId, token);
            handleWalletUpdate(this.liFiWidget.wallet.fully);
        }
        catch (e) {
            devError(e);
        }
        return;
    },
};
export default liFiWidgetActions;
async function handleWalletUpdate(wallet) {
    const walletStore = useWalletStore();
    walletStore.liFiWidget.wallet.fully = wallet;
    walletStore.liFiWidget.wallet.account = await extractAccountFromSigner(wallet?.account?.signer);
}
async function extractAccountFromSigner(signer) {
    try {
        return {
            address: (await signer?.getAddress()) || undefined,
            isActive: (signer && !!(await signer.getAddress()) === null) || !!signer,
            signer: signer ? markRaw(signer) : undefined,
            chainId: (await signer?.getChainId()) || undefined,
        };
    }
    catch {
        return {};
    }
}
