import { useWeb3Store } from '@/web3-server';
import adapter from '@/store/strategies/actions/common/strategies.adapter';
import { useCurrenciesStore } from '@/store/currencies';
import { devError } from '@/utils/devError';
import { decimalFormatting } from '@/composables/useFormatCurrency';
import { useStrategiesStore } from '@/store/perfect-strategies';
import { updatePercentageYield } from '@/store/strategies/actions/common/composables/update-percentage-yield';
import { updateEarnedIncome } from '@/store/strategies/actions/common/composables/update-earned-income';
import checker from '@/web3-server/utils/checker';
import { DateTime } from 'luxon';
import { useWeb3StrategiesStore } from '@/web3-server/web3-strategies';
import { useWeb3Currencies } from '@/web3-server/web3-currencies';
import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import { CONTRACT_TYPE } from '@/web3-server/web3/types/strategies/constants';
const commonActions = {
    cleanStrategies(networkId) {
        this.listOfActiveStrategies.forEach((strategy) => {
            strategy.earnedIncome.balance = '';
            strategy.isDeposit = false;
            strategy.horizon.deposit.earnedBLID = '0';
            strategy.horizon.deposit.depositAmountInUSD = '0';
            strategy.horizon.deposit.percentageYield = '0';
            strategy.horizon.deposit.depositedBoostInBLID = undefined;
            const totalDepositElement = this.totalDeposit[strategy.id];
            if (totalDepositElement) {
                totalDepositElement.depositAmountInUSD = 0;
                totalDepositElement.earnedBLID = 0;
            }
        });
        this.updateStatusAndListOfTokensForStrategies(networkId);
    },
    generalDataInit(BLIDPrice) {
        const web3Store = useWeb3Store();
        const currenciesStore = useCurrenciesStore();
        const lpTokenAddress = currenciesStore.getAddress(currenciesStore.config.currencyTypes.BLID_USDT);
        const getContract = async (contract, networkId) => {
            return web3Store.getGeneralData(contract, BLIDPrice[networkId] || BLIDPrice.BNB, currenciesStore.getAddress(currenciesStore.config.currencyTypes.BLID, networkId), { lpTokenAddress }, networkId);
        };
        this.listOfActiveStrategies.forEach((strategy) => {
            getContract(strategy.contract, strategy.network)
                .then((contract) => {
                if (contract) {
                    if ('boostingPromise' in contract) {
                        contract.tvlPromise
                            .then((tvl) => {
                            strategy.horizon.defaultNums.tvl = tvl.total;
                            strategy.horizon.formatted.tvl = adapter.getTVL(tvl.total);
                            if (strategy.horizon.extraTVL) {
                                strategy.horizon.extraTVL.deposited = tvl.deposited;
                                strategy.horizon.extraTVL.borrowed = tvl.borrowed;
                            }
                        })
                            .finally(() => (strategy.horizon.dataLoaded.tvl = true));
                        contract.boostingPromise
                            .then((boosting) => {
                            strategy.horizon.defaultNums.maxBoosting = {
                                apr: boosting.response.apr.original,
                                apy: boosting.response.apy.original,
                                pureAPR: boosting.response.pureAPR.original,
                            };
                            strategy.horizon.formatted.maxBoosting = {
                                apr: adapter.getBoosting(boosting.response.apr.roundedDown),
                                apy: adapter.getBoosting(boosting.response.apy.roundedDown),
                                pureAPR: adapter.getBoosting(boosting.response.pureAPR.roundedDown),
                            };
                            strategy.horizon.showBoostBtn =
                                !!boosting.response.apy.original && boosting.isBoostingV2;
                            strategy.horizon.isBoostingV2 = boosting.isBoostingV2;
                        })
                            .finally(() => (strategy.horizon.dataLoaded.maxBoosting = true));
                        contract.dateOfLastDistributionPromise.then((ms) => {
                            if (ms) {
                                strategy.horizon.dateOfLastDistribution = DateTime.fromMillis(ms).toLocaleString(DateTime.DATETIME_SHORT);
                            }
                        });
                    }
                    else {
                        contract.tvlPromise
                            .then((tvl) => {
                            strategy.horizon.defaultNums.tvl = tvl;
                            strategy.horizon.formatted.tvl = adapter.getTVL(tvl);
                        })
                            .finally(() => (strategy.horizon.dataLoaded.tvl = true));
                    }
                    contract.apyPromise
                        .then(({ apr, apy }) => {
                        strategy.horizon.defaultNums.apr = apr;
                        strategy.horizon.defaultNums.apy = apy;
                        strategy.horizon.formatted.apr = adapter.getAPYOrAPR(apr);
                        strategy.horizon.formatted.apy = adapter.getAPYOrAPR(apy);
                    })
                        .finally(() => {
                        strategy.horizon.dataLoaded.apy = true;
                    });
                }
            })
                .catch((e) => {
                devError(e);
            });
        });
    },
    generalDataInitForAllStrategies(BLIDPrice) {
        const web3Store = useWeb3Store();
        const currenciesStore = useCurrenciesStore();
        const lpTokenAddress = currenciesStore.getAddress(currenciesStore.config.currencyTypes.BLID_USDT);
        const getContract = async (contract, networkId) => {
            return web3Store.getGeneralData(contract, BLIDPrice[networkId] || BLIDPrice.BNB, currenciesStore.getAddress(currenciesStore.config.currencyTypes.BLID, networkId), { lpTokenAddress }, networkId);
        };
        const responseForAllStrategies = web3Store.getGeneralDataFromStorageContractForAllStrategies([
            NETWORK_ID.BNB,
            NETWORK_ID.POLYGON,
            NETWORK_ID.ARBITRUM,
        ]);
        if (responseForAllStrategies) {
            responseForAllStrategies.tvlPromise.then((tvlByStrategyId) => {
                tvlByStrategyId.forEach((tvl) => {
                    const strategy = this.getCurrentStrategy({ id: tvl.strategyId });
                    if (strategy) {
                        strategy.horizon.defaultNums.tvl = tvl.value.total;
                        strategy.horizon.formatted.tvl = adapter.getTVL(tvl.value.total);
                        if (strategy.horizon.extraTVL) {
                            strategy.horizon.extraTVL.deposited = tvl.value.deposited;
                            strategy.horizon.extraTVL.borrowed = tvl.value.borrowed;
                        }
                        strategy.horizon.dataLoaded.tvl = true;
                    }
                });
            });
            responseForAllStrategies.boostingPromise.then((boostingByStrategyId) => {
                boostingByStrategyId.response.forEach((boosting) => {
                    const strategy = this.getCurrentStrategy({ id: boosting.strategyId });
                    if (strategy) {
                        strategy.horizon.defaultNums.maxBoosting = {
                            apr: boosting.value.apr.original,
                            apy: boosting.value.apy.original,
                            pureAPR: boosting.value.pureAPR.original,
                        };
                        strategy.horizon.formatted.maxBoosting = {
                            apr: adapter.getBoosting(boosting.value.apr.roundedDown),
                            apy: adapter.getBoosting(boosting.value.apy.roundedDown),
                            pureAPR: adapter.getBoosting(boosting.value.pureAPR.roundedDown),
                        };
                        strategy.horizon.showBoostBtn =
                            !!boosting.value.apy.original && boostingByStrategyId.isBoostingV2;
                        strategy.horizon.isBoostingV2 = boostingByStrategyId.isBoostingV2;
                        strategy.horizon.dataLoaded.maxBoosting = true;
                    }
                });
            });
            responseForAllStrategies.dateOfLastDistributionPromise.then((msByStrategyId) => {
                msByStrategyId.forEach((ms) => {
                    if (ms.value) {
                        const strategy = this.getCurrentStrategy({ id: ms.strategyId });
                        if (strategy) {
                            strategy.horizon.dateOfLastDistribution = DateTime.fromMillis(ms.value).toLocaleString(DateTime.DATETIME_SHORT);
                        }
                    }
                });
            });
            responseForAllStrategies.apyPromise.then((apyByStrategyId) => {
                apyByStrategyId.forEach((item) => {
                    const { apr, apy } = item.value;
                    const strategy = this.getCurrentStrategy({ id: item.strategyId });
                    if (strategy) {
                        strategy.horizon.defaultNums.apr = apr;
                        strategy.horizon.defaultNums.apy = apy;
                        strategy.horizon.formatted.apr = adapter.getAPYOrAPR(apr);
                        strategy.horizon.formatted.apy = adapter.getAPYOrAPR(apy);
                        strategy.horizon.dataLoaded.apy = true;
                    }
                });
            });
            responseForAllStrategies.logicContractAddressesPromise.then((logicContractAddresses) => {
                logicContractAddresses.forEach((item) => {
                    const strategy = this.getCurrentStrategy({ id: item.strategyId });
                    if (strategy && strategy.addressesOfLogicContracts) {
                        strategy.addressesOfLogicContracts.list = item.list;
                    }
                });
            });
        }
        this.listOfActiveStrategies
            .filter((item) => item.contract.type !== CONTRACT_TYPE.STORAGE)
            .forEach((strategy) => {
            getContract(strategy.contract, strategy.network)
                .then((contract) => {
                if (contract) {
                    if ('boostingPromise' in contract) {
                        contract.tvlPromise
                            .then((tvl) => {
                            strategy.horizon.defaultNums.tvl = tvl.total;
                            strategy.horizon.formatted.tvl = adapter.getTVL(tvl.total);
                            if (strategy.horizon.extraTVL) {
                                strategy.horizon.extraTVL.deposited = tvl.deposited;
                                strategy.horizon.extraTVL.borrowed = tvl.borrowed;
                            }
                        })
                            .finally(() => (strategy.horizon.dataLoaded.tvl = true));
                        contract.boostingPromise
                            .then((boosting) => {
                            strategy.horizon.defaultNums.maxBoosting = {
                                apr: boosting.response.apr.original,
                                apy: boosting.response.apy.original,
                                pureAPR: boosting.response.pureAPR.original,
                            };
                            strategy.horizon.formatted.maxBoosting = {
                                apr: adapter.getBoosting(boosting.response.apr.roundedDown),
                                apy: adapter.getBoosting(boosting.response.apy.roundedDown),
                                pureAPR: adapter.getBoosting(boosting.response.pureAPR.roundedDown),
                            };
                            strategy.horizon.showBoostBtn =
                                !!boosting.response.apy.original && boosting.isBoostingV2;
                            strategy.horizon.isBoostingV2 = boosting.isBoostingV2;
                        })
                            .finally(() => (strategy.horizon.dataLoaded.maxBoosting = true));
                        contract.dateOfLastDistributionPromise.then((ms) => {
                            if (ms) {
                                strategy.horizon.dateOfLastDistribution = DateTime.fromMillis(ms).toLocaleString(DateTime.DATETIME_SHORT);
                            }
                        });
                    }
                    else {
                        contract.tvlPromise
                            .then((tvl) => {
                            strategy.horizon.defaultNums.tvl = tvl;
                            strategy.horizon.formatted.tvl = adapter.getTVL(tvl);
                        })
                            .finally(() => (strategy.horizon.dataLoaded.tvl = true));
                    }
                    contract.apyPromise
                        .then(({ apr, apy }) => {
                        strategy.horizon.defaultNums.apr = apr;
                        strategy.horizon.defaultNums.apy = apy;
                        strategy.horizon.formatted.apr = adapter.getAPYOrAPR(apr);
                        strategy.horizon.formatted.apy = adapter.getAPYOrAPR(apy);
                    })
                        .finally(() => {
                        strategy.horizon.dataLoaded.apy = true;
                    });
                }
            })
                .catch((e) => {
                devError(e);
            });
        });
    },
    currentUserDataInit(strategy) {
        const web3Store = useWeb3Store();
        const currenciesStore = useCurrenciesStore();
        const BLIDTokenAddress = currenciesStore.getAddress(currenciesStore.config.currencyTypes.BLID, strategy.network);
        const lpTokenAddress = currenciesStore.getAddress(currenciesStore.config.currencyTypes.BLID_USDT);
        return web3Store
            .getUserInfo(strategy.contract, {
            value: strategy.horizon.defaultNums,
            loaded: strategy.horizon.dataLoaded,
        }, {
            BLIDTokenAddress,
            lpTokenAddress,
        }, strategy.network)
            .then((response) => {
            if (response) {
                const { isDeposit, USDDeposit, earnedBLID, earnedUSD, percentageYield, userBoostingPromise, depositFromBoost, leftAfterDeposit, averageDistributionRewardPromise, } = response;
                strategy.isDeposit = isDeposit;
                const strategyDeposit = strategy.horizon.deposit;
                if (leftAfterDeposit && leftAfterDeposit.state) {
                    strategy.horizon.leftAfterDeposit.earnedBLID = leftAfterDeposit.detailed.earnedBLID;
                    strategy.horizon.leftAfterDeposit.boost = leftAfterDeposit.detailed.boost;
                }
                else {
                    strategy.horizon.leftAfterDeposit.earnedBLID = false;
                    strategy.horizon.leftAfterDeposit.boost = false;
                }
                if (depositFromBoost &&
                    depositFromBoost.short &&
                    checker.decimal.isNotEmpty(depositFromBoost.short)) {
                    strategyDeposit.depositedBoostInBLID = depositFromBoost;
                }
                else {
                    strategyDeposit.depositedBoostInBLID = undefined;
                }
                if (isDeposit) {
                    const totalDepositElement = this.totalDeposit[strategy.id];
                    if (totalDepositElement) {
                        totalDepositElement.depositAmountInUSD = USDDeposit;
                    }
                    strategyDeposit.depositAmountInUSD = decimalFormatting(USDDeposit, 2, {
                        showWithManyZeros: true,
                    });
                    this.updateEarnedIncomeWithPercent(earnedBLID, earnedUSD, percentageYield, strategy);
                    if (userBoostingPromise) {
                        userBoostingPromise
                            .then((boosting) => {
                            strategy.horizon.defaultNums.userBoosting = {
                                apr: boosting.apr.original,
                                apy: boosting.apy.original,
                            };
                            strategy.horizon.formatted.userBoosting = {
                                apr: adapter.getBoosting(boosting.apr.roundedDown),
                                apy: adapter.getBoosting(boosting.apy.roundedDown),
                            };
                        })
                            .finally(() => (strategy.horizon.dataLoaded.userBoosting = true));
                    }
                    if (averageDistributionRewardPromise) {
                        averageDistributionRewardPromise.then((averageDistributionReward) => {
                            strategy.horizon.deposit.averageDistributionReward = decimalFormatting(averageDistributionReward, 2, { showWithManyZeros: true });
                        });
                    }
                }
                else {
                    const totalDepositElement = this.totalDeposit[strategy.id];
                    if (earnedBLID) {
                        strategy.earnedIncome.balance = decimalFormatting(earnedBLID, 2, {
                            showWithManyZeros: true,
                        });
                    }
                    else if (strategy.earnedIncome.balance) {
                        strategy.earnedIncome.balance = '0';
                    }
                    if (strategyDeposit.depositAmountInUSD && strategyDeposit.depositAmountInUSD !== '0') {
                        strategyDeposit.depositAmountInUSD = '0';
                        strategyDeposit.earnedBLID = '0';
                        strategyDeposit.percentageYield = '0';
                    }
                    if (totalDepositElement) {
                        if (totalDepositElement.earnedBLID) {
                            totalDepositElement.earnedBLID = 0;
                            totalDepositElement.earnedUSD = 0;
                        }
                        if (totalDepositElement.depositAmountInUSD) {
                            totalDepositElement.depositAmountInUSD = 0;
                        }
                    }
                    if ((strategy.horizon.defaultNums.userBoosting &&
                        (strategy.horizon.defaultNums.userBoosting.apr ||
                            strategy.horizon.defaultNums.userBoosting.apy)) ||
                        (strategy.horizon.formatted.userBoosting &&
                            (strategy.horizon.formatted.userBoosting.apr !== '0' ||
                                strategy.horizon.formatted.userBoosting.apy !== '0'))) {
                        if (strategy.horizon.defaultNums.userBoosting) {
                            strategy.horizon.defaultNums.userBoosting.apy = 0;
                            strategy.horizon.defaultNums.userBoosting.apr = 0;
                        }
                        if (strategy.horizon.formatted.userBoosting) {
                            strategy.horizon.formatted.userBoosting.apr = '0';
                            strategy.horizon.formatted.userBoosting.apy = '0';
                        }
                    }
                }
                return response;
            }
        });
    },
    userDataInit() {
        const listOfPromises = [];
        this.listOfActiveStrategies.forEach((strategy) => {
            const promise = this.currentUserDataInit(strategy);
            if (promise) {
                listOfPromises.push(promise);
            }
        });
        return listOfPromises;
    },
    updateEarnedIncomeWithPercent(earnedBLID, earnedUSD, percentageYield, strategy) {
        updateEarnedIncome(earnedBLID, earnedUSD, strategy, this.totalDeposit[strategy.id]);
        updatePercentageYield(percentageYield, strategy);
    },
    updateStatusAndListOfTokensForStrategies(networkId) {
        const web3StrategiesStore = useWeb3StrategiesStore();
        const web3CurrenciesStore = useWeb3Currencies();
        const strategiesStore = useStrategiesStore();
        const allCurrencies = web3CurrenciesStore.getWholeList();
        const tags = web3CurrenciesStore.getListOfTags(allCurrencies.byStrategies);
        const listOfStrategyStatuses = web3StrategiesStore.getStatusList(networkId);
        if (listOfStrategyStatuses.length) {
            listOfStrategyStatuses.forEach((item) => {
                const strategy = strategiesStore.list.find((strategy) => strategy.id === item.strategyId);
                if (strategy) {
                    strategy.horizon.strategyStatus = item.status;
                }
            });
        }
        if (tags.length) {
            tags.forEach((item) => {
                const strategy = strategiesStore.list.find((strategy) => strategy.id === item.strategyId);
                if (strategy) {
                    strategy.horizon.listOfTokens = item.currencies;
                }
            });
        }
    },
};
export default commonActions;
