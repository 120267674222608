import { NETWORK_ID, WALLET_NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import { CURRENCY_TYPE } from '@/infra/types/currency/constants';
import { networkForStargate } from '@/web3-server/wallet/data/cross-chain/network-for-stargate';
import { BRIDGE_TYPE } from '@/web3-server/web3/types/cross-chain/constants';
import defaultNetwork from '@/web3-server/wallet/data/cross-chain/default-network';
import { STRATEGY_VAULT } from '@/web3-server/web3-strategies/types/config/constants';
export const walletNetwork = [
    {
        id: NETWORK_ID.BNB,
        walletId: WALLET_NETWORK_ID.BNB_STARGATE,
        name: defaultNetwork.BNB.name,
        icon: defaultNetwork.BNB.icon,
        blockExplorerUrl: defaultNetwork.BNB.blockExplorerUrl,
        rpcUrl: defaultNetwork.BNB.rpcUrl,
        chainId: {
            hex: '0x' + Number(process.env.VUE_APP_RIGHT_CHAIN_ID || '56').toString(16),
            int: parseInt(process.env.VUE_APP_RIGHT_CHAIN_ID || '56', 10),
        },
        currency: {
            type: 'BNB',
            decimals: 18,
        },
        crossChain: networkForStargate.BNB,
        allowedToSwitch: true,
        blockPerYear: '10512000',
    },
    {
        id: NETWORK_ID.POLYGON,
        walletId: WALLET_NETWORK_ID.POLYGON_STARGATE,
        name: defaultNetwork.POLYGON.name,
        icon: defaultNetwork.POLYGON.icon,
        blockExplorerUrl: defaultNetwork.POLYGON.blockExplorerUrl,
        rpcUrl: defaultNetwork.POLYGON.rpcUrl,
        chainId: {
            hex: '0x89',
            int: 137,
        },
        currency: {
            type: CURRENCY_TYPE.MATIC,
            decimals: 18,
        },
        crossChain: networkForStargate.POLYGON,
        allowedToSwitch: true,
        blockPerYear: '15768000',
    },
    {
        id: NETWORK_ID.ETH,
        walletId: WALLET_NETWORK_ID.ETH_STARGATE,
        name: defaultNetwork.ETH.name,
        icon: defaultNetwork.ETH.icon,
        blockExplorerUrl: defaultNetwork.ETH.blockExplorerUrl,
        rpcUrl: defaultNetwork.ETH.rpcUrl,
        chainId: {
            hex: '0x1',
            int: 1,
        },
        currency: {
            type: CURRENCY_TYPE.ETH,
            decimals: 18,
        },
        crossChain: networkForStargate.ETH,
        allowedToSwitch: true,
        blockPerYear: '0',
    },
    {
        id: NETWORK_ID.ARBITRUM,
        walletId: WALLET_NETWORK_ID.ARBITRUM,
        name: defaultNetwork.ARBITRUM.name,
        icon: defaultNetwork.ARBITRUM.icon,
        blockExplorerUrl: defaultNetwork.ARBITRUM.blockExplorerUrl,
        rpcUrl: defaultNetwork.ARBITRUM.rpcUrl,
        chainId: {
            hex: '0xa4b1',
            int: 42161,
        },
        currency: {
            type: CURRENCY_TYPE.ETH,
            decimals: 18,
        },
        crossChain: undefined,
        allowedToSwitch: true,
        blockPerYear: '95000000',
    },
    {
        id: NETWORK_ID.OPTIMISM,
        walletId: WALLET_NETWORK_ID.OPTIMISM,
        name: defaultNetwork.OPTIMISM.name,
        icon: defaultNetwork.OPTIMISM.icon,
        blockExplorerUrl: defaultNetwork.OPTIMISM.blockExplorerUrl,
        rpcUrl: defaultNetwork.OPTIMISM.rpcUrl,
        chainId: {
            hex: '0xa',
            int: 10,
        },
        currency: {
            type: CURRENCY_TYPE.ETH,
            decimals: 18,
        },
        crossChain: undefined,
        allowedToSwitch: false,
        blockPerYear: '0',
    },
    {
        id: NETWORK_ID.GOERLI,
        walletId: WALLET_NETWORK_ID.GOERLI,
        name: defaultNetwork.GOERLI.name,
        icon: defaultNetwork.GOERLI.icon,
        blockExplorerUrl: defaultNetwork.GOERLI.blockExplorerUrl,
        rpcUrl: defaultNetwork.GOERLI.rpcUrl,
        chainId: {
            hex: '0x5',
            int: 5,
        },
        currency: {
            type: CURRENCY_TYPE.ETH,
            decimals: 18,
        },
        crossChain: undefined,
        allowedToSwitch: false,
        blockPerYear: '0',
        isTestNetwork: true,
    },
];
export const walletNetworkById = {
    [NETWORK_ID.BNB]: walletNetwork[0],
    [NETWORK_ID.POLYGON]: walletNetwork[1],
    [NETWORK_ID.ETH]: walletNetwork[2],
    [NETWORK_ID.ARBITRUM]: walletNetwork[3],
    [NETWORK_ID.OPTIMISM]: walletNetwork[4],
    [NETWORK_ID.GOERLI]: walletNetwork[5],
};
export const walletNetworkByWalletId = {
    [WALLET_NETWORK_ID.BNB_STARGATE]: walletNetwork[0],
    [WALLET_NETWORK_ID.POLYGON_STARGATE]: walletNetwork[1],
    [WALLET_NETWORK_ID.ETH_STARGATE]: walletNetwork[2],
    [WALLET_NETWORK_ID.ARBITRUM]: walletNetwork[3],
    [WALLET_NETWORK_ID.OPTIMISM]: walletNetwork[4],
    [WALLET_NETWORK_ID.GOERLI]: walletNetwork[5],
};
export const walletNetworkByVault = {
    [STRATEGY_VAULT.BNB]: walletNetwork[0],
    [STRATEGY_VAULT.BNB_V2]: walletNetwork[0],
    [STRATEGY_VAULT.POLYGON]: walletNetwork[1],
    [STRATEGY_VAULT.ETH]: walletNetwork[2],
    [STRATEGY_VAULT.ARBITRUM]: walletNetwork[3],
};
export const currencyForNetwork = {
    [NETWORK_ID.POLYGON]: CURRENCY_TYPE.MATIC,
    [NETWORK_ID.ETH]: CURRENCY_TYPE.ETH,
};
export const networkForCrossChain = {
    [BRIDGE_TYPE.STARGATE]: networkForStargate,
};
export const networkForAdd = {
    [NETWORK_ID.POLYGON]: {
        chainId: '0x89',
        rpcUrls: ['https://polygon-rpc.com/'],
        chainName: 'Polygon Mainnet',
        nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
        },
        blockExplorerUrls: ['https://polygonscan.com/'],
    },
    [NETWORK_ID.BNB]: {
        chainId: '0x38',
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        chainName: 'BNB Smart Chain Mainnet',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
        },
        blockExplorerUrls: ['https://bscscan.com/'],
    },
    [NETWORK_ID.ETH]: {
        chainId: '0x1',
        rpcUrls: ['https://rpc.ankr.com/eth'],
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
            name: 'Ethereum',
            symbol: 'ETH',
            decimals: 18,
        },
        blockExplorerUrls: ['https://etherscan.io/'],
    },
    [NETWORK_ID.ARBITRUM]: {
        chainId: '0xa4b1',
        rpcUrls: ['https://arb1.arbitrum.io/rpc'],
        chainName: 'Arbitrum One',
        nativeCurrency: {
            name: 'Ethereum',
            symbol: 'ETH',
            decimals: 18,
        },
        blockExplorerUrls: ['https://arbiscan.io/'],
    },
    [NETWORK_ID.OPTIMISM]: {
        chainId: '0xa',
        rpcUrls: ['https://mainnet.optimism.io'],
        chainName: 'OP Mainnet',
        nativeCurrency: {
            name: 'Ethereum',
            symbol: 'ETH',
            decimals: 18,
        },
        blockExplorerUrls: ['https://optimistic.etherscan.io/'],
    },
    [NETWORK_ID.GOERLI]: {
        chainId: '0x5',
        rpcUrls: ['https://rpc.ankr.com/eth_goerli'],
        chainName: 'Goerli',
        nativeCurrency: {
            name: 'Ethereum',
            symbol: 'ETH',
            decimals: 18,
        },
        blockExplorerUrls: ['https://goerli.etherscan.io/'],
    },
};
