import { STRATEGY_VAULT } from '@/web3-server/web3-strategies/types/config/constants';
export const strategyVaultColors = {
    [STRATEGY_VAULT.BNB]: {
        idle: '#e5b10d',
        hover: '#ffd935',
        disabled: '#e5b10d',
        pressed: '#c79300',
        tailwind: {
            bg: 'bnb-vault bnb-vault_hover',
            text: 'bnb-vault-text',
            textTag: 'bnb-vault-text',
        },
    },
    [STRATEGY_VAULT.BNB_V2]: {
        idle: '#e5b10d',
        hover: '#ffd935',
        disabled: '#e5b10d',
        pressed: '#c79300',
        tailwind: {
            bg: 'bnb-vault',
            text: 'bnb-vault-text',
            textTag: 'bnb-vault-text',
        },
    },
    [STRATEGY_VAULT.ETH]: {
        idle: '#6e8af6',
        hover: '#8ca8ff',
        disabled: '',
        pressed: '',
        tailwind: {
            bg: 'eth-vault',
            text: 'eth-vault-text',
            textTag: 'eth-vault-text',
        },
    },
    [STRATEGY_VAULT.POLYGON]: {
        idle: '#9f6efd',
        hover: '#c796ff',
        disabled: '',
        pressed: '',
        tailwind: {
            bg: 'polygon-vault',
            text: 'polygon-vault-text',
            textTag: 'polygon-vault-text',
        },
    },
    [STRATEGY_VAULT.ARBITRUM]: {
        idle: '#6e8af6',
        hover: '#8ca8ff',
        disabled: '',
        pressed: '',
        tailwind: {
            bg: 'eth-vault',
            text: 'eth-vault-text',
            textTag: 'eth-vault-text',
        },
    },
};
export const strategyVaultNames = {
    [STRATEGY_VAULT.BNB]: {
        short: 'BNB',
        full: 'BNB',
        section: 'BNB Chain',
    },
    [STRATEGY_VAULT.BNB_V2]: {
        short: 'BNB V2',
        full: 'BNB V2',
        section: 'BNB Chain V2',
    },
    [STRATEGY_VAULT.ETH]: {
        short: 'ETH',
        full: 'Ethereum',
        section: 'ETH Vaults',
    },
    [STRATEGY_VAULT.POLYGON]: {
        short: 'Polygon',
        full: 'Polygon',
        section: 'Polygon',
    },
    [STRATEGY_VAULT.ARBITRUM]: {
        short: 'ARB',
        full: 'Arbitrum',
        section: 'Arbitrum',
    },
};
