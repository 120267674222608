export var STRATEGY_TYPE;
(function (STRATEGY_TYPE) {
    STRATEGY_TYPE["STABLECOINS"] = "STABLECOINS";
    STRATEGY_TYPE["BTC"] = "BTC";
    STRATEGY_TYPE["ETH"] = "ETH";
    STRATEGY_TYPE["ALTCOINS"] = "ALTCOINS";
    STRATEGY_TYPE["BLID"] = "BLID";
    STRATEGY_TYPE["BLID_USDT"] = "BLID_USDT";
})(STRATEGY_TYPE || (STRATEGY_TYPE = {}));
export var STRATEGY_ID;
(function (STRATEGY_ID) {
    STRATEGY_ID["BNB_STABLECOINS"] = "BNB_STABLECOINS";
    STRATEGY_ID["BNB_ETH"] = "BNB_ETH";
    STRATEGY_ID["BNB_BTC"] = "BNB_BTC";
    STRATEGY_ID["BNB_BLID"] = "BNB_BLID";
    STRATEGY_ID["BNB_BLID_USDT"] = "BNB_BLID_USDT";
    STRATEGY_ID["BNB_ALTCOINS"] = "BNB_ALTCOINS";
    STRATEGY_ID["POLYGON_USDT"] = "POLYGON_USDT";
    STRATEGY_ID["BNB_BNB"] = "BNB_BNB";
    STRATEGY_ID["BNB_USDT_V2"] = "BNB_USDT_V2";
    STRATEGY_ID["POLYGON_USDC"] = "POLYGON_USDC";
    STRATEGY_ID["POLYGON_MATIC"] = "POLYGON_MATIC";
    STRATEGY_ID["POLYGON_DAI"] = "POLYGON_DAI";
    STRATEGY_ID["POLYGON_ETH"] = "POLYGON_ETH";
    STRATEGY_ID["ARBITRUM_USDC_E"] = "ARBITRUM_USDC_E";
    STRATEGY_ID["DEV_BNB_USDT_DFORCE"] = "DEV_BNB_USDT_DFORCE";
    STRATEGY_ID["DEV_POLYGON_STABLECOINS"] = "DEV_POLYGON_STABLECOINS";
})(STRATEGY_ID || (STRATEGY_ID = {}));
export var STRATEGY_ROUTE;
(function (STRATEGY_ROUTE) {
    STRATEGY_ROUTE["STABLECOINS_BNB"] = "stablecoins";
    STRATEGY_ROUTE["ALTCOINS_BNB"] = "altcoins";
    STRATEGY_ROUTE["ETH_BNB"] = "eth";
    STRATEGY_ROUTE["BTC_BNB"] = "btc";
    STRATEGY_ROUTE["BLID_BNB"] = "stake-blid";
    STRATEGY_ROUTE["BLID_USDT_BNB"] = "farming-blid-usdt";
    STRATEGY_ROUTE["STABLECOINS_ETH"] = "stablecoins-eth";
    STRATEGY_ROUTE["USDT_POLYGON"] = "usdt-polygon";
    STRATEGY_ROUTE["BNB_BNB"] = "bnb";
    STRATEGY_ROUTE["USDT_V2_BNB"] = "usdt-v2-bnb";
    STRATEGY_ROUTE["POLYGON_USDC"] = "usdc-polygon";
    STRATEGY_ROUTE["POLYGON_MATIC"] = "matic-polygon";
    STRATEGY_ROUTE["POLYGON_DAI"] = "dai-polygon";
    STRATEGY_ROUTE["POLYGON_ETH"] = "eth-polygon";
    STRATEGY_ROUTE["ARBITRUM_USDC_E"] = "usdc-e-arbitrum";
    STRATEGY_ROUTE["DEV_BNB_USDT_DFORCE"] = "usdt-dforce";
    STRATEGY_ROUTE["DEV_POLYGON_STABLECOINS"] = "stablecoins-polygon";
})(STRATEGY_ROUTE || (STRATEGY_ROUTE = {}));
export var STRATEGY_VAULT;
(function (STRATEGY_VAULT) {
    STRATEGY_VAULT["BNB"] = "BNB";
    STRATEGY_VAULT["BNB_V2"] = "BNB_V2";
    STRATEGY_VAULT["ETH"] = "ETH";
    STRATEGY_VAULT["POLYGON"] = "POLYGON";
    STRATEGY_VAULT["ARBITRUM"] = "ARBITRUM";
})(STRATEGY_VAULT || (STRATEGY_VAULT = {}));
export var STRATEGY_STATUS;
(function (STRATEGY_STATUS) {
    STRATEGY_STATUS["DEPOSIT"] = "DEPOSIT";
    STRATEGY_STATUS["CROSS_CHAIN"] = "CROSS_CHAIN";
    STRATEGY_STATUS["DISABLED"] = "DISABLED";
})(STRATEGY_STATUS || (STRATEGY_STATUS = {}));
