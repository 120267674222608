import { decimalFormatting, } from '@/composables/useFormatCurrency';
import { computed } from 'vue';
import Decimal from 'decimal.js';
import { STRATEGY_ID } from '@/web3-server/web3-strategies/types/config/constants';
export function useTippy(strategy) {
    const isDesktop = () => window.matchMedia('(min-width: 1024px)').matches && !strategy.value?.isComingSoon;
    const isMobile = () => window.matchMedia('(max-width: 1023px)').matches && !strategy.value?.isComingSoon;
    const defaultDecimalFormattingExtra = {
        transformLargeNums: false,
        isRounding: 'down',
    };
    const getAPRContent = () => {
        const APR = strategy.value?.defaultNums.apr
            ? decimalFormatting(strategy.value.defaultNums.apr, 2, defaultDecimalFormattingExtra)
            : '0';
        return `APY is based on your one-year income if Claim and Compound are made once a day. Provided APY calculation based on ${APR}% APR`;
    };
    const getAPYContent = () => {
        let boosting = '0';
        let text = getAPRContent();
        if (strategy.value?.isBoostingV2) {
            if (strategy.value?.defaultNums.userBoosting?.apr &&
                strategy.value.formatted.userBoosting &&
                strategy.value.formatted.userBoosting.apr) {
                let isBoostCalcZero = true;
                const userBoostingWithoutAPY = decimalFormatting(new Decimal(strategy.value?.defaultNums.userBoosting.apr).minus(strategy.value?.defaultNums.apr), 2, defaultDecimalFormattingExtra);
                if (strategy.value?.defaultNums.maxBoosting &&
                    strategy.value?.defaultNums.maxBoosting.apr) {
                    const calc = new Decimal(strategy.value.defaultNums.maxBoosting.apr).minus(strategy.value?.defaultNums.userBoosting.apr || 0);
                    isBoostCalcZero = calc.isZero() || calc.isNegative();
                    boosting = decimalFormatting(calc, 2, defaultDecimalFormattingExtra);
                }
                else {
                    boosting = decimalFormatting(strategy.value?.defaultNums.userBoosting.apr, 2, defaultDecimalFormattingExtra);
                }
                text = text.replace('APR', `rewards APR and ${userBoostingWithoutAPY}% boosting APR.` +
                    (isBoostCalcZero
                        ? ''
                        : ` Boost with BLID tokens to have up to ${boosting}% APR additionally`));
            }
            else if (strategy.value?.defaultNums.maxBoosting &&
                strategy.value?.formatted.maxBoosting &&
                strategy.value?.defaultNums.maxBoosting.pureAPR &&
                strategy.value?.deposit.depositAmountInUSD &&
                strategy.value.deposit.depositAmountInUSD !== '0') {
                boosting = decimalFormatting(strategy.value.defaultNums.maxBoosting.pureAPR, 2, defaultDecimalFormattingExtra);
                text = text.replace('APR', `rewards APR.\nBoost with BLID tokens to have up to ${boosting}% APR additionally`);
            }
        }
        return text;
    };
    const getBoostAPYContent = () => {
        let boosting = '0';
        let text = getAPRContent();
        if (strategy.value?.defaultNums.maxBoosting &&
            strategy.value.formatted.maxBoosting &&
            strategy.value?.defaultNums.maxBoosting.pureAPR) {
            boosting = decimalFormatting(strategy.value.defaultNums.maxBoosting.pureAPR, 2, defaultDecimalFormattingExtra);
            text = text.replace('APR', `rewards APR and maximum ${boosting}% boosting APR`);
        }
        return text;
    };
    const getTVLContent = () => {
        let content = '';
        if (strategy.value?.defaultNums.tvl && isFinite(strategy.value.defaultNums.tvl)) {
            if (strategy.value?.extraTVL) {
                if (strategy.value?.extraTVL.deposited) {
                    content += `$${decimalFormatting(strategy.value?.extraTVL.deposited, 2)} Deposited assets <br>`;
                }
                if (strategy.value?.extraTVL.borrowed) {
                    content += `$${decimalFormatting(strategy.value?.extraTVL.borrowed, 2)} Borrowed assets <br>`;
                }
            }
            content += `$${decimalFormatting(strategy.value.defaultNums.tvl, 2)} Total Value Locked`;
        }
        else {
            content = '$0 Total Value Locked';
        }
        return content;
    };
    const getEarnedContent = () => {
        let content = 'Reward distribution occurs every 12 hours. ';
        if (strategy.value) {
            const isDistributionOnceDay = [STRATEGY_ID.ARBITRUM_USDC_E].includes(strategy.value?.id);
            if (isDistributionOnceDay) {
                content = content.replace('12', '24');
            }
            if (strategy.value?.dateOfLastDistribution) {
                content += `The last distribution was completed at ${strategy.value?.dateOfLastDistribution}`;
            }
            if (strategy.value?.deposit.averageDistributionReward &&
                strategy.value?.deposit.averageDistributionReward !== '0') {
                content += `<br/>Your projected income is ${strategy.value?.deposit.averageDistributionReward} BLID per ${isDistributionOnceDay ? '24' : '12'} hours`;
            }
        }
        return content;
    };
    const defaultParams = {
        maxWidth: 230,
        theme: 'tomato',
    };
    return {
        boostAPYDesktop: computed(() => {
            if (isDesktop()) {
                return {
                    ...defaultParams,
                    placement: 'right-start',
                    offset: [-10, 10],
                    content: getBoostAPYContent(),
                };
            }
            else {
                return undefined;
            }
        }),
        boostAPYMobile: computed(() => {
            if (isMobile()) {
                return {
                    ...defaultParams,
                    trigger: 'click',
                    placement: 'bottom-end',
                    content: getBoostAPYContent(),
                };
            }
            else {
                return undefined;
            }
        }),
        APYDesktop: computed(() => {
            if (isDesktop()) {
                return {
                    ...defaultParams,
                    placement: 'right-start',
                    offset: [-10, 10],
                    content: getAPYContent(),
                };
            }
            else {
                return undefined;
            }
        }),
        APYMobile: computed(() => {
            if (isMobile()) {
                return {
                    ...defaultParams,
                    trigger: 'click',
                    placement: 'bottom-end',
                    content: getAPYContent(),
                };
            }
            else {
                return undefined;
            }
        }),
        TVLDesktop: computed(() => {
            if (isDesktop()) {
                return {
                    ...defaultParams,
                    placement: 'right',
                    allowHTML: true,
                    content: getTVLContent(),
                };
            }
            else {
                return undefined;
            }
        }),
        TVLMobile: computed(() => {
            if (isMobile()) {
                return {
                    ...defaultParams,
                    trigger: 'click',
                    placement: 'right',
                    allowHTML: true,
                    content: getTVLContent(),
                };
            }
            else {
                return undefined;
            }
        }),
        earnedIncomeDesktop: computed(() => {
            if (isDesktop()) {
                return {
                    ...defaultParams,
                    placement: 'right',
                    allowHTML: true,
                    content: getEarnedContent(),
                };
            }
            else {
                return undefined;
            }
        }),
        earnedIncomeMobile: computed(() => {
            if (isMobile()) {
                return {
                    ...defaultParams,
                    trigger: 'click',
                    allowHTML: true,
                    content: getEarnedContent(),
                };
            }
            else {
                return undefined;
            }
        }),
    };
}
